<template>
  <div class="form-group">
    <slot name="label">
      <label for="editor-format">{{ $t("format") }}</label>
    </slot>
    <template v-if="toggleIcon">
      <div class="input-group">
        <div
          class="input-group-addon btn"
          @click.stop.prevent="$emit('toggle')"
          style="padding: 0 6px"
        >
          <i :class="toggleIcon"></i>
        </div>
        <input
          type="text"
          class="form-control"
          v-model="value"
          id="editor-format"
          :placeholder="`${$tc('eg', 2)} %d`"
          list="formatList"
          autocomplete="off"
          data-testid="format"
          style="background-color:transparent;"
        />
        <datalist id="formatList">
          <template v-for="(item, ix) in formatList">
            <option :value="item" :key="ix" />
          </template>
        </datalist>
      </div>
    </template>
    <template v-else>
      <input
        type="text"
        class="form-control"
        v-model="value"
        id="editor-format"
        :placeholder="`${$tc('eg', 2)} %d`"
        list="formatList"
        autocomplete="off"
        data-testid="format"
        style="background-color:transparent;"
      />
      <datalist id="formatList">
        <template v-for="(item, ix) in formatList">
          <option :value="item" :key="ix" />
        </template>
      </datalist>
    </template>
    <slot name="after"></slot>
  </div>
</template>

<script>
export default {
  name: "DataFormatInput",
  props: {
    control: {
      type: Object,
      required: true,
      default: () => null
    },
    toggleIcon: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    value: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this?.control?.format || "";
      }
    },
    formatList() {
      let lst = this.$root.config.references.data_value_format_types
        .map(({ format_mask }) => format_mask)
        .filter(
          (item) => ["text_list", "duration", "custom"].indexOf(item) == -1
        );
      if (this.control.data_id) {
        let data = (this.$store.getters["dashboard/dataList"] || []).find(
          (i) => i.id == this.control.data_id
        );
        if (data && data.custom_format) {
          lst.push(data.custom_format);
        }
      }
      return lst;
    }
  }
};
</script>

<style scoped>
.form-group {
  position: relative;
}
</style>
