<template>
  <div class="form-group form-group-sm limit-box">
    <div
      class="input-group"
      :style="{width: iType == 'constant' ? 'inherit' : '100%'}"
      v-if="sourceTypeList.length > 1"
    >
      <div class="input-group-addon label-addon">
        <span class="text-primary text-bold">{{ $t("type") }}</span>
      </div>
      <select
        class="form-control form-group-sm no-padding type-selector"
        v-model="iType"
      >
        <option :value="o.id" v-for="o in sourceTypeList" :key="o.id">
          {{ $t(o.title) }}
        </option>
      </select>
      <div class="input-group-addon no-padding" v-if="iType == 'constant'">
        <input
          type="number"
          class="form-control no-padding constant"
          :placeholder="$t('value')"
          v-model="iValue"
        />
      </div>
    </div>
    <ControlDataSelector
      v-if="iType == 'data'"
      :addon="$tc('data')"
      v-model="iDataId"
      label=""
    />
    <ValueSourceSelector
      v-if="iDataValueIndex"
      v-model="iDataValueIndex"
      :offset="true"
      :connectorId="dataConnectorId"
      :exclude="[iDataId]"
    >
      <template #label>
        <div>
          <label for="data_value_index"
            >{{ $t("titles.data_value_index") }}
            <ToolTip
              :title="`${$t('hints.data_value_index')}<br/>${$tc(
                'interval',
                1
              )}: &ge; 0 &amp;&amp; &le; ${dataMemorySize - 1}`"
            />
          </label>
          <div class="pull-right">
            <span
              class="small"
              v-if="iDataValueIndex.type == 'constant'"
              :title="`${$t('numeric').toLowerCase()}`"
            >
              &ge; 0 &amp;&amp; &le;
              {{ dataMemorySize - 1 }}
              &nbsp;&nbsp;
            </span>
            <span
              class="small"
              v-else
              :title="`${$t('numeric').toLowerCase()} ${$t(
                'or'
              ).toLowerCase()} ${$t(
                'expression'
              ).toLowerCase()}\nex: $value*10+3`"
            >
              <i class="fa fa-code"></i>
            </span>
          </div>
        </div>
      </template>
    </ValueSourceSelector>
  </div>
</template>

<script>
import ControlDataSelector from "./control-data-selector";
import ValueSourceSelector from "@/components/editor/value-source-selector.vue";
import ToolTip from "@/components/tooltip.vue";
import {initialValue as DftDataValueIndex} from "@/components/editor/value-source-selector.vue";

const dftSourceValue = () => ({
  type: "constant",
  value: 0,
  data_id: "",
  data_value_index: {
    type: "constant",
    data_id: "",
    value: 0,
    offset: 0,
    enabled: true
  }
});

export {dftSourceValue};
export default {
  name: "ValueSourceForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    defaultValue: {
      type: Number,
      required: false,
      default: 0
    },
    typeList: {
      type: Array,
      required: false,
      default: () => ["constant", "data"]
    }
  },
  components: {
    ControlDataSelector,
    ValueSourceSelector,
    ToolTip
  },
  data: () => ({
    form: dftSourceValue()
  }),
  computed: {
    allowNull() {
      return this.typeList.indexOf("none") >= 0;
    },
    sourceTypeList() {
      const available = {
        none: "undefined",
        constant: "constant",
        data: "from_data"
      };
      return this.typeList.map((id) => ({id: id, title: available[id]}));
    },
    selectedData() {
      return (
        (this.iDataId &&
          this.$store.getters["dashboard/dataList"].find(
            ({id}) => parseInt(id) === parseInt(this.iDataId)
          )) ??
        null
      );
    },
    dataConnectorId() {
      return this?.selectedData?.clp_id;
    },
    dataMemorySize() {
      return (this.selectedData && this.selectedData?.memory_size) || 1; // at least a position
    },
    iType: {
      set(value) {
        this.form.type = value;
        if (value == "constant") {
          this.iDataId = "";
          this.iValue = this.defaultValue;
        }
        this.triggerChanges();
      },
      get() {
        return this.form.type;
      }
    },
    iDataId: {
      set(value) {
        this.form.data_id = value;
        this.triggerChanges();
      },
      get() {
        return this.form.data_id;
      }
    },
    iValue: {
      set(value) {
        this.form.value = parseFloat(value);
        this.triggerChanges();
      },
      get() {
        return this.form.value;
      }
    },
    iDataValueIndex: {
      set(value) {
        if (!(this.dataMemorySize > 1) || !value) return;
        let entry = {...value};
        if (entry.type == "constant") {
          entry.value =
            entry.value !== "" && !isNaN(Number(entry.value))
              ? parseInt(entry.value)
              : -1;
          if (entry.value < 0) entry.value = -1;
          else if (entry.value >= this.dataMemorySize)
            entry.value = this.dataMemorySize - 1;
        }
        this.form.data_value_index = entry;
        this.triggerChanges();
      },
      get() {
        if (!(this.dataMemorySize > 1)) return null;
        let entry = this.form.data_value_index || DftDataValueIndex();
        if (entry.type == "constant" && !(entry.value >= 0)) {
          entry.value = 0;
        }
        return entry;
      }
    }
  },
  methods: {
    triggerChanges() {
      let payload = null; // type none;
      if (this.iType == "constant") {
        payload = dftSourceValue();
        payload.type = this.iType;
        delete payload.data_id;
        delete payload.data_value_index;
        payload.value = this.iValue;
      } else if (this.iType == "data") {
        payload = dftSourceValue();
        payload.type = this.iType;
        delete payload.value;
        payload.data_id = this.iDataId;
        payload.data_value_index = this.iDataValueIndex;
      }
      this.$emit("input", payload);
    }
  },
  created() {
    // backward compatibility
    if (this.value || !this.allowNull) {
      const value = {...(this.value || {})};
      if ((this.typeList || []).length == 1 && this.typeList[0] != "none") {
        this.form.type = this.typeList[0];
      } else {
        this.form.type =
          value.type || ("data_id" in value ? "data" : "constant");
      }
      if (this.form.type == "constant") {
        this.form.value = value.value;
      } else {
        this.form.data_id = value.data_id;
      }
      if (value.data_value_index) {
        this.form.data_value_index = {...value.data_value_index};
      }
    } else {
      this.iType = "none";
    }
  }
};
</script>

<style scoped>
.limit-box {
  margin-top: 5px;
  margin-left: -5px;
  padding-left: 5px;
}

.label-addon {
  width: 74px;
}

.constant {
  width: 70px;
  height: 28px !important;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-align: center;
}

input[type="number"] {
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.form-group-sm select.form-control.type-selector {
  height: 31px; /* one pixel extra to compensate any zoom */
  overflow: hidden;
}
</style>